<template>
  <div>
    <custom-toolbar
      :total="$store.getters['user/total_admin']"
      :title="$t('Administrators')"
      :add="'Nouvel administrateur'"
      @refresh="getAdmins"
      @add="openForm"
    />
    <v-card class="mb-6 mt-6">
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.user.fieldAdmin.name"
              label="Rechercher par le nom"
              outlined
              dense
              placeholder="Rechercher par le nom"
              hide-details
              clearable
              @input="getAdminsByFields"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.user.fieldAdmin.keyword"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details
              clearable
              @input="getAdminsByFields"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="$store.state.user.fieldAdmin.role_id"
              :items="$store.getters['role/roles']"
              item-text="label"
              item-value="id"
              label="Rechercher par rôle"
              outlined
              dense
              placeholder="Rechercher par rôle"
              clearable
              hide-details="auto"
              persistent-hint
              single-line
              @input="getAdminsByFields"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement.."
        :headers="headers"
        :items="$store.getters['user/admins']"
        :items-per-page="itemsPerPage"
        :page.sync="currentPage"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.profile_picture_path ? '' : 'primary'"
              :class="item.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.profile_picture_path"
                :src="`${item.profile_picture_path}`"
              ></v-img>
              <span v-else-if="item.name">{{ item.name !== null && item.name !== undefined ? item.name.slice(0,2).toUpperCase() : null }}</span>
              <v-img
                v-else
                :src="require('@/assets/images/avatars/1.png')"
              />
            </v-avatar>
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name ? item.name : null }}</span>
              <small>{{ item.role.label }}</small>
            </div>
          </div>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :status="false"
            :lock="false"
            :remove="false"
            @view="openItem(item)"
            @edit="openForm(item)"
          />
        </template>
        <template #[`item.is_locked`]="{item}">
          <v-chip
            small
            :color="isLockedColor[is_locked[item.is_locked]]"
            :class="`${isLockedColor[is_locked[item.is_locked]]}--text`"
            class="v-chip-light-bg"
          >
            {{ item.is_locked === true ? 'OUI' : 'NON' }}
          </v-chip>
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.user.fieldAdmin.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getAdminsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getAdmins"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <user-form-dialog
      :dialog="dialog"
      :user="user"
      @clickout="closeDialog"
      @submitted="loading"
    />
    <confirm-dialog
      :message="confirmMessage"
      :dialog-delete="dialogDelete"
      @clickout="closeDelete"
      @submitted="lockUser"
    />
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiAccountLock,
  mdiLockOpen,
  mdiPlus,
  mdiEyeOutline,
  mdiRefresh,
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import UserFormDialog from '@core/components/user/UserFormDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { formatDate } from '@core/date/dateFunctions'
import { findPage } from '@core/dynamicComponents/dynamicComponents'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import { ref, onMounted, watch } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default {
  name: 'Admins',
  components: {
    ButtonsGroup,
    CustomToolbar,
    ConfirmDialog,
    UserFormDialog,
    LoaderDialog,
  },

  setup() {
    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const confirmMessage = ref('Voulez-vous bloquer cet utilisateur?')
    const userForm = {
      id: null,
      name: null,
      email: null,
      birthday: null,
      lang: null,
      civility: null,
      password: null,
      password_confirmation: null,
      bio: null,
      preference: null,
      role_id: null,
      country_code: null,
    }
    const user = ref(null)
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }

    const itemsPerPage = ref(parseInt(store.state.user.fieldAdmin.paginate, 10))

    const formattingDate = date => formatDate(date)

    const deleteItemConfirm = () => {
      // this.userList.splice(this.editedIndex, 1)
      closeDelete()
    }

    const openItem = item => {
      router.push({ name: 'showuser', params: { id: item.id } })
    }

    const lockUser = () => {
      // this.userList.splice(this.editedIndex, 1)
      closeDelete()
    }

    const deleteItem = item => {
      dialogDelete.value = true
    }

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const getAdmins = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('user/getAdminsList', { page, field: store.state.user.fieldAdmin }).then(() => {
        currentPage.value = store.getters['user/current_admin_page']
        lastPage.value = store.getters['user/last_admin_page']
        isDialogVisible.value = false
      }).catch(error => {
        console.log(error)
      })
    }

    const refreshAdmins = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('user/refreshAdminsList', { page, field: store.state.user.fieldAdmin }).then(() => {
        currentPage.value = store.getters['user/current_admin_page']
        lastPage.value = store.getters['user/last_admin_page']
        isDialogVisible.value = false
      }).catch(error => {
        console.log(error)
      })
    }
    // eslint-disable-next-line camelcase
    const getAdminsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.user.fieldAdmin.paginate = per_page
        itemsPerPage.value = parseInt(per_page, 10)
        store.dispatch('user/getAdminsList', {
          page: currentPage.value,
          field: store.state.user.fieldAdmin,
        }).then(() => {
          currentPage.value = store.getters['user/current_admin_page']
          lastPage.value = store.getters['user/last_admin_page']
          isDialogVisible.value = false
        })
      }
    }

    const getAdminsByFields = () => {
      isDialogVisible.value = true
      store.dispatch('user/getAdminsList', {
        page: 1,
        field: store.state.user.fieldAdmin,
      }).then(() => {
        currentPage.value = store.getters['user/current_admin_page']
        lastPage.value = store.getters['user/last_admin_page']
        isDialogVisible.value = false
      })
    }

    const openForm = (item = null) => {
      if (item !== null) {
        item.birthday = new Date(item.birthday).toISOString().substr(0, 10)
        user.value = item
      } else user.value = userForm
      dialog.value = true
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    onMounted(() => {
      getAdmins(store.getters['user/current_admin_page'])
      user.value = userForm
    })

    watch(() => store.state.user.lang, lang => {
      refreshAdmins(store.getters['user/current_admin_page'])
    })

    return {
      refreshAdmins,
      loading,
      openForm,
      getAdminsByFields,
      getAdminsPerPage,
      getAdmins,
      closeDialog,
      closeDelete,
      deleteItem,
      lockUser,
      openItem,
      deleteItemConfirm,
      formattingDate,
      currentPage,
      lastPage,
      dialogDelete,
      isDialogVisible,
      dialog,
      confirmMessage,
      userForm,
      user,
      headers: [
        { text: 'NOM', value: 'name' },
        { text: 'EMAIL', value: 'email' },
        { text: 'TEL', value: 'phone_number' },
        { text: 'BLOQUE?', value: 'is_locked' },
        { text: "DATE D'INSCRIPTION", value: 'created_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage,
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
        mdiRefresh,
      },
    }
  },
}
</script>

<style scoped>

</style>
